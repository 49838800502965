<template>
  <div class="decorationBox">
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="直播间信息：">
        <el-input size="small" v-model="keyword" placeholder="请输入直播间名称、主播名称、房间号"></el-input>
      </el-form-item>
      <el-form-item label="直播间状态：">
        <el-select v-model="liveStatus" size="small" placeholder="请选择">
          <el-option v-for="item in liveStatusArr" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="searchFn">搜索</el-button>
        <el-button type="text" size="small" @click="clearSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :cell-style="() => 'text-align:center'" :data="liveRoomList" :header-cell-style="{ 'background-color': '#F8F9FA', 'text-align': 'center' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column label="最近开播">
        <template v-slot="{ row }">
          <div class="starttime">{{ row.starttime.month }}月{{ row.starttime.day }}日 {{ row.starttime.hour }}：{{ row.starttime.minutes }}</div>
          <div class="endtime">至{{ row.endtime.month }}月{{ row.endtime.day }}日 {{ row.endtime.hour }}：{{ row.endtime.minutes }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="date" label="直播间信息">
        <template v-slot="{ row }">
          <div class="coverImg">
            <img :src="row.cover_img_url" alt="" />
            {{ row.title }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="room_id" label="房间号"></el-table-column>
      <el-table-column prop="nick_name" label="主播昵称"></el-table-column>
      <el-table-column prop="date" label="直播商品数">
        <template v-slot="{ row }">
          {{ !row.goods_num ? 0 : row.goods_num }}
        </template>
      </el-table-column>
      <el-table-column prop="date" label="直播状态">
        <template v-slot="{ row }">
          {{
            row.live_status == 101
              ? '直播中'
              : row.live_status == 102
              ? '未开始'
              : row.live_status == 103
              ? '已结束'
              : row.live_status == 104
              ? '禁播'
              : row.live_status == 105
              ? '暂停'
              : row.live_status == 106
              ? '异常'
              : '已过期'
          }}
        </template>
      </el-table-column>
      <el-table-column prop="date" label="操作">
        <template v-slot="{ row }">
          <el-button type="text" @click="$router.push(`/extension/console?room=${row.room_id}`)">控制台</el-button>
          <el-button v-if="row.live_status == 101 || row.live_status == 102" type="text" @click="openLiveFlag = true">开播码</el-button>
          <el-button v-if="row.live_status != 107" type="text" @click="getShareInfo(row.room_id)">分享</el-button>
          <el-button v-if="row.live_status == 102" type="text" @click="updateLiveRoom(row.room_id)">编辑</el-button>
          <el-button v-if="row.live_status == 102" type="text" @click="delLiveRoom(row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total" :page="page" :pageNum="pageNums" @updatePageNum="updateData"></Paging>
    <el-dialog title="开播码" :visible.sync="openLiveFlag" width="500px">
      <div class="openLiveBox">
        <img src="@/assets/image/liveRoom/openLive.png" alt="" />
        <p class="bold">小程序直播·主播端</p>
        <p>微信扫码进入主播小程序</p>
      </div>
    </el-dialog>
    <el-dialog title="分享" :visible.sync="shareInfoFlag" width="800px">
      <div class="shareBox">
        <div class="shareBoxTop">
          <div class="shareTLeft">
            <div>
              <p class="title">直播间分享海报</p>
              <p class="desc">小程序码不带参数</p>
              <div class="downBtn" @click="downLoadImg(sharePoster)">保存图片</div>
            </div>
            <div>
              <img style="width: 160px; height: 210px" :src="sharePoster" alt="" />
            </div>
          </div>
          <div class="shareTRight">
            <div>
              <p class="title">直播间小程序码</p>
              <p class="desc">小程序码不带参数</p>
              <div class="downBtn" @click="downLoadImg(smallCode)">保存图片</div>
            </div>
            <div>
              <img style="width: 120px; height: 120px" :src="smallCode" alt="" />
            </div>
          </div>
        </div>
        <div class="shareBoxBot">
          <div>直播间页面路径</div>
          <div class="bot">
            <div class="path">{{ liveRoomPath }}</div>
            <div class="downBtn" @click="copyPath($event)">复制链接</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Paging from '@/components/paging';
import getDate from '@/util/getDate.js';
import clipboard from '@/util/clipBoard.js';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      page: 1,
      pageNums: 10,
      total: 0,
      liveRoomList: [],
      keyword: '',
      liveStatus: -1,
      liveStatusArr: [
        {
          value: -1,
          name: '全部',
        },
        {
          value: 101,
          name: '直播中',
        },
        {
          value: 102,
          name: '未开始',
        },
        {
          value: 103,
          name: '已结束',
        },
        {
          value: 104,
          name: '禁播',
        },
        {
          value: 105,
          name: '暂停',
        },
        {
          value: 106,
          name: '异常',
        },
        {
          value: 107,
          name: '已过期',
        },
      ],
      // 是否显示开播码
      openLiveFlag: false,
      // 显示分享信息
      shareInfoFlag: false,
      sharePoster: '',
      smallCode: '',
      liveRoomPath: '',
    };
  },
  methods: {
    getLiveList() {
      let that = this;
      this.$axios
        .post(this.$api.push.liveRoom.liveBookList, {
          page: this.page,
          rows: this.pageNums,
          keyword: this.keyword,
          status: this.liveStatus === -1 ? null : this.liveStatus,
        })
        .then(res => {
          that.liveRoomList = res.result.list;
          if (res.result.total !== 0) {
            that.liveRoomList.map(item => {
              item.starttime = getDate.getDate(item.starttime * 1000);
              item.endtime = getDate.getDate(item.endtime * 1000);
            });
          }
          that.total = res.result.total;
        });
    },
    // 修改分页数据
    updateData(val, status) {
      if (status == 0) {
        this.pageNums = val;
      } else {
        this.page = val;
      }
      // 重新获取数据
      this.getLiveList();
    },
    // 点击进行搜索
    searchFn() {
      this.page = 1;
      this.getLiveList();
    },
    // 清空搜索
    clearSearch() {
      this.page = 1;
      this.keyword = '';
      this.liveStatus = -1;
      this.getLiveList();
    },
    // 获取直播间的分享信息
    getShareInfo(id) {
      let that = this;
      this.$axios
        .post(this.$api.push.liveRoom.getSharedCode, {
          roomId: id,
        })
        .then(res => {
          if (res.code == 0) {
            that.sharePoster = res.result.posterUrl_base;
            that.smallCode = res.result.cdnUrl_base;
            that.liveRoomPath = res.result.pagePath;
            that.shareInfoFlag = true;
          } else {
            that.$message.error(res.msg);
          }
        });
    },
    copyPath($event) {
      clipboard($event, this.liveRoomPath);
    },
    downLoadImg(path) {
      let a = document.createElement('a');
      a.href = path;
      a.download = '';
      a.id = 'download';
      document.body.appendChild(a);
      a.click();
      let aDom = document.getElementById('download');
      document.body.removeChild(aDom);
    },
    // 修改直播间
    updateLiveRoom(id) {
      this.$router.push(`/extension/addAppletLiveBroadcast?room=${id}`);
    },
    // 删除直播间
    delLiveRoom(row) {
      let that = this;
      this.$confirm('此操作将在删除此直播间, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          return that.$axios.post(that.$api.push.liveRoom.deleteLiveRoom, {
            id: row.room_id,
          });
        })
        .then(res => {
          if (res.code === 0) {
            that.$message.success('删除成功');
            that.getLiveList();
          } else {
            that.$message.error(res.msg);
          }
        })
        .catch();
    },
  },
};
</script>

<style lang="less" scoped>
.decorationBox {
  height: 100%;
  display: flex;
  flex-direction: column;

  .el-table {
    height: 100%;
    /deep/ .el-table__body-wrapper {
      height: calc(100% - 48px);
      overflow-y: auto;
    }
  }
  .starttime {
    font-size: 18px;
  }
  .coverImg {
    display: flex;
    align-items: center;
    img {
      width: 80px;
      height: 80px;
      margin: 0 10px 0 20px;
    }
  }
  .openLiveBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    .bold {
      font-weight: bold;
      font-size: 16px;
    }
  }
  .shareBox {
    .downBtn {
      width: 110px;
      height: 32px;
      border-radius: 3.2px;
      background-color: #ffffff;
      color: #6467f0;
      font-size: 14px;
      line-height: 32px;
      margin-top: 20px;
      text-align: center;
      cursor: pointer;
      user-select: none;
    }
    .shareBoxTop {
      display: flex;
      justify-content: space-between;
      & > div {
        display: flex;
        width: 362px;
        flex-shrink: 0;
        background-color: #f7f7f7;
        padding: 20px;
        justify-content: space-between;
        .title {
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.9);
        }
        .desc {
          font-size: 14px;
          line-height: 20px;
          color: rgba(0, 0, 0, 0.55);
          margin-top: 8px;
        }
      }
    }
    .shareBoxBot {
      width: 100%;
      margin-top: 20px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: #f7f7f7;
      .bot {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 16px;
        .path {
          width: 482px;
        }
        .downBtn {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
